import cc from 'card-validator';
import { DialogAction, DialogActions, Errors, Form, Input, Label, TextField } from '@troon/ui';
import { action, useSubmission } from '@solidjs/router';
import { createEffect, Match, Show, Switch } from 'solid-js';
import { useTrackEvent } from '@troon/analytics';
import { CreditCardBrand, gql, getApiClient } from '../graphql';
import { revalidate } from '../graphql/cache';
import type { DocumentType } from '../graphql';
import type { Track } from '@troon/analytics';
import type { OperationResult } from '@urql/core';

export const addCard = (track: Track, onSuccess?: (cardId: string) => Promise<void> | void) =>
	action(async (formData): Promise<OperationResult<DocumentType<typeof addCardMutation>, Record<string, string>>> => {
		const validationErrors = [];
		const cardNumber = formData.get('cc-number').replace(/\s+/g, '');
		const cardValidation = cc.number(cardNumber);
		if (!cardValidation.isValid) {
			validationErrors.push({
				field: 'cc-number',
				displayMessage: 'Please enter a valid credit card number.',
			});
		}
		const expiration = cc.expirationDate(formData.get('cc-exp'));
		if (!expiration.isValid) {
			validationErrors.push({
				field: 'cc-exp',
				displayMessage: 'Please enter a valid expiration date.',
			});
		}
		const cvv = cc.cvv(formData.get('cc-csc'), cardValidation.card?.code.size);
		if (!cvv.isValid) {
			validationErrors.push({ field: 'cc-csc', displayMessage: 'Please enter a valid CVV.' });
		}
		const postalCode = formData.get('postal-code').replace(/\s+/g, '');
		const postalCodeValidation = cc.postalCode(postalCode);
		if (!postalCodeValidation.isValid) {
			validationErrors.push({
				field: 'postal-code',
				displayMessage: 'Please enter a valid postal code.',
			});
		}
		if (!formData.get('cc-name')) {
			validationErrors.push({
				field: 'cc-name',
				displayMessage: 'Please enter a the name as it appears on the credit card.',
			});
		}
		if (validationErrors.length) {
			return {
				error: {
					graphQLErrors: [
						// @ts-expect-error partial fake graphql error
						{
							extensions: {
								displayMessage:
									'There was an error validating the credit card information. Please verify all fields and try again.',
								fields: validationErrors,
							},
						},
					],
				},
			};
		}

		const tokenRes = await getApiClient().mutation(createPaymentTokenMutation, {});
		if (tokenRes.error) {
			return tokenRes;
		}
		const { token: uploadToken, tokenTypeId, uploadUrl } = tokenRes.data!.createPaymentToken;

		let year = expiration.year ? parseInt(expiration.year, 10) : null;
		const month = expiration.month ? parseInt(expiration.month, 10) : null;
		let expirationYear = expiration.year;
		let expirationMonth = expiration.month;
		if (year && month) {
			year = year < 2000 ? 2000 + year : year;
			const parsed = new Date(year, month - 1);

			expirationMonth = `${parsed.getMonth() + 1}`.padStart(2, '0');
			expirationYear = `${parsed.getFullYear() - 2000}`;
		}

		let uploadRes: Response | undefined = undefined;
		let uploadData = undefined;
		let failed = false;
		try {
			uploadRes = await fetch(uploadUrl, {
				method: 'POST',
				mode: 'cors',
				cache: 'no-cache',
				credentials: 'omit',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					token: { token: uploadToken, tokenTypeId },
					cardNumber,
					nameOnCard: formData.get('cc-name'),
					expirationMonth,
					expirationYear,
					postalCode,
				}),
			});
			uploadData = await uploadRes.json();
		} catch {
			failed = true;
		}

		if (failed || !uploadRes?.ok || !uploadData?.Token) {
			return {
				error: {
					graphQLErrors: [
						// @ts-expect-error partial fake graphql error
						{
							extensions: {
								displayMessage:
									'There was an error validating the credit card information. Please verify all fields and try again.',
								fields: Object.entries(uploadData.errors ?? {})
									.map(([key, errors]) =>
										key in ccFieldToName
											? {
													field: ccFieldToName[key as string],
													displayMessage: (errors as Array<string>)[0],
												}
											: false,
									)
									.filter(Boolean),
							},
						},
					],
				},
			};
		}

		const { card } = cc.number(formData.get('cc-number'))!;

		if (!card) {
			return {
				error: {
					graphQLErrors: [
						// @ts-expect-error partial fake graphql error
						{
							extensions: {
								displayMessage:
									'There was an error validating the credit card information. Please verify all fields and try again.',
								fields: [],
							},
						},
					],
				},
			};
		}

		const data = {
			lastFour: formData.get('cc-number')!.slice(-4),
			brand: card.type in cardTypeToEnum ? cardTypeToEnum[card.type]! : CreditCardBrand.Other,
			token: uploadData.Token,
		};

		const res = await getApiClient().mutation(addCardMutation, data);
		if (!res.error && res.data?.addCreditCard) {
			track('addStoredCreditCard');
			await revalidate(['paymentMethods'], true);
			if (onSuccess) {
				await onSuccess(res.data!.addCreditCard.id);
			}
		}

		return res;
	}, 'addCard');

type Props = {
	buttonText?: string;
	onCancel?: () => void;
	onSuccess?: (creditCardId: string) => Promise<void> | void;
};

export function AddCard(props: Props) {
	const track = useTrackEvent();
	// eslint-disable-next-line solid/reactivity
	const onAddCard = addCard(track, props.onSuccess);
	const data = useSubmission(onAddCard);
	createEffect(() => {
		if (!data.pending && !data.error && data.result && !data.result.error) {
			data.clear();
		}
	});

	return (
		<Form action={onAddCard} document={addCardMutation}>
			<AddCardFields />
			<Errors />

			<AddCardButtons {...props} />
		</Form>
	);
}

export function AddCardFields() {
	return (
		<div class="flex flex-col gap-y-6">
			<TextField name="cc-number">
				<Label>Credit card number</Label>
				<Input autocomplete="cc-number" inputMode="numeric" required />
			</TextField>

			<div class="grid grid-cols-2 gap-x-4 gap-y-2 sm:grid-cols-3">
				<TextField name="cc-exp">
					<Label>Expiration</Label>
					<Input autocomplete="cc-exp" placeholder="MM/YY" required />
				</TextField>

				<TextField name="cc-csc">
					<Label>CVC</Label>
					<Input autocomplete="cc-csc" inputMode="numeric" required />
				</TextField>

				<TextField name="postal-code" class="col-span-2 sm:col-span-1">
					<Label>Postal code</Label>
					<Input autocomplete="postal-code" required />
				</TextField>
			</div>

			<TextField name="cc-name">
				<Label>Name on card</Label>
				<Input required autocomplete="cc-name" />
			</TextField>
		</div>
	);
}

export function AddCardButtons(props: Props) {
	return (
		<div class="flex flex-col gap-y-6">
			<DialogActions>
				<DialogAction type="submit">
					<Switch>
						<Match when={props.buttonText}>{props.buttonText}</Match>
						<Match when={!props.buttonText}>Add card</Match>
					</Switch>
				</DialogAction>
				<Show when={props.onCancel}>
					<DialogAction type="button" appearance="transparent" onClick={props.onCancel}>
						Cancel
					</DialogAction>
				</Show>
			</DialogActions>
		</div>
	);
}

const createPaymentTokenMutation = gql(`
mutation createPaymentToken {
  createPaymentToken {
    token
		tokenTypeId
		uploadUrl
  }
}`);

export const addCardMutation = gql(`
mutation addCreditCard($lastFour: String!, $brand: CreditCardBrand!, $token: String!) {
  addCreditCard(lastFour: $lastFour, brand: $brand, token: $token) {
    id
    lastFour
    brand
  }
}`);

const ccFieldToName: Record<string, string> = {
	CardNumber: 'cc-number',
	ExpirationMonth: 'cc-exp',
	ExpirationYear: 'cc-exp',
	NameOnCard: 'cc-name',
	PostalCode: 'postal-code',
};

const cardTypeToEnum: Record<string, CreditCardBrand> = {
	'american-express': CreditCardBrand.Amex,
	visa: CreditCardBrand.Visa,
	mastercard: CreditCardBrand.Mastercard,
	discover: CreditCardBrand.Discover,
};
